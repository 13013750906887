import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Preview } from '@kogk/gatsby-theme-prismic'
import getLinkResolver from '@src/data/get-link-resolver'

export const staticQuery = graphql`
  query {
    site {
      siteMetadata {
        defaultFrontpageTag
        defaultLang
      }
    }
  }
`

export default () => {
  const {
    site: {
      siteMetadata: { defaultFrontpageTag, defaultLang }
    }
  } = useStaticQuery(staticQuery)

  const linkResolver = getLinkResolver(defaultLang, defaultFrontpageTag)

  return <Preview linkResolver={linkResolver} />
}
