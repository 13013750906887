module.exports = (defaultLang, defaultFrontpageTag) => page => {
  if (!page.lang && !page.uid) {
    return
  }

  const lang = page.lang.substring(0, 2)
  const isFront = page.tags && page.tags.indexOf(defaultFrontpageTag) !== -1

  const parts = ['/']

  if (lang !== defaultLang) {
    parts.push(lang, '/')
  }
  if (!isFront) {
    parts.push(page.uid, '/')
  }
  return parts.join('')
}
